<template>
  <v-app
    id="materialpro"
    :class="`${
      !$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'
    }`"
  >
    <router-view />
  </v-app>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "App",
  components: {},
  computed: {
    ...mapState({
      autoLogout: (state) => state.user.isLogout,
    }),
  },

  watch: {
    autoLogout(curValue, oldValue) {
      if (curValue && curValue != oldValue) {
        this.$router.replace("/auth/login");
      }
    },
  },
};
</script>
<style lang="scss">
.absolute {
  position: absolute;
}
.absolute {
  position: relative;
}
.cursor-pointer {
  cursor: pointer;
}
.font-size-12 {
  font-size: 12px;
}
.font-weight-800 {
  font-weight: 800;
}
.font-weight-bold {
  font-weight: bold;
}
</style>
